.leftbar {
  float: left;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 54px;
  border-right: 1px solid #e1e3e5;
  left: 0;
  width: 240px;
  z-index: 99;
}
