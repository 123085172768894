@import "~antd/dist/antd.less";

/* 消除 antd 和 shopify 样式冲突 */
p {
  margin-bottom: 0;
}

// ant 组件 palceholder color 颜色使用shopify的颜色
.ant-picker-input input {
  &::placeholder {
    color: var(--p-text-disabled);
  }
}

// drawer 组件把顶部的 x 按钮放到右边
.ant-drawer-header-title {
  .ant-drawer-title {
    font-size: 20px;
  }
  .ant-drawer-close {
    order: 1;
    margin: 0;
  }
}

// PolarisPortalsContainer
// Polaris-PositionedOverlay Polaris-Popover__PopoverOverlay Polaris-Popover__PopoverOverlay--entering
// shopify 层级不够的问题
#PolarisPortalsContainer {
  // .PositionedOverlay
  .Polaris-Popover__PopoverOverlay {
    z-index: 521 !important;
  }
}
.Polaris-Modal-Dialog__Container {
  z-index: 521 !important;
}

.Polaris-Layout {
  margin-bottom: 16px;
}

