.Polaris-IndexTable__Table {
  .Polaris-IndexTable__TableRow {
    .Polaris-IndexTable__TableCell {
      .coopListItem {
        // overflow-wrap: break-word;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis ;
        // word-break: normal ;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        // display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; //最多行
      }
    }
  }
}
