.system__modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 516;
  background-color: var(--p-background);
  .system__modal__header {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    border-bottom: 0.1rem solid var(--p-divider);
    background: #fff;
    .system__modal__header__settings {
      word-wrap: break-word;
      word-break: break-word;
      flex: 1 1;
      height: 100%;
      display: flex;
      align-items: center;
      .system__modal__header__settings--inside {
        display: flex;
        align-items: center;
        padding: 0 2rem;
        height: 52px;
        .system__modal__header__settings--inside__icon {
          fill: var(--p-icon);
        }
        .system__modal__header__settings--inside__text {
          color: var(--p-text);
          margin-left: 1.6rem;
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 0em;
        }
      }
    }
    .system__modal__header__close {
      display: flex;
      align-items: center;
      padding: 0 2rem;
      height: 52px;
    }
  }
  .system__modal__content {
    height: calc(100% - 52px);
    width: 100%;
    overflow: auto;
    .system__modal__content__body {
      display: flex;
      justify-content: center;
      padding: 1.6rem 0 0 3.2rem;
      .system__modal__content__body__nav {
        display: inline-block;
        width: 18rem;
        padding-top: 1.6rem;
        padding-bottom: 4.8rem;
        // background-color: red;
        height: 300px;
        > nav {
          background-color: var(--p-surface);
          box-shadow: var(
            --p-card-shadow,
            0 0 0 1px rgba(63, 63, 68, 0.05),
            0 1px 3px 0 rgba(63, 63, 68, 0.15)
          );
          outline: 0.1rem solid transparent;
          border-radius: 0.8rem;
          overflow: hidden;
          > div {
            background: var(--p-surface-subdued);
            padding: 2rem;
            > p {
              margin-top: 0.4rem;
            }
          }
          > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            > li > div {
              border-top: 0.1rem solid var(--p-divider);
              &.action {
                color: var(--p-text-success);
              }
              > a {
                color: inherit;
                text-decoration: none;
                > div {
                  display: flex;
                  align-items: flex-start;
                  > div:nth-of-type(1) {
                    width: 0.4rem;
                    height: 3.2rem;
                    background-color: var(--p-action-primary);
                    border-radius: 0 0.4rem 0.4rem 0;
                    margin-top: 0.6rem;
                    margin-right: -0.4rem;
                    display: none;
                    &.action {
                      display: block;
                    }
                  }
                  > div:nth-of-type(2) {
                    display: flex;
                    flex: 1 1;
                    padding: 1.2rem 2rem 0.6rem;
                    > span {
                      font-weight: 600;
                      flex: 1 1;
                      margin-left: 1.6rem;
                    }
                  }
                }
              }
            }
          }
        }
        @media screen and (max-width: 900px) {
          width: 24rem;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          padding-top: 0;
          margin-bottom: 3.2rem;
        }
      }
      .system__modal__content__body__content {
        width: 92rem;
        // max-width: calc(100% - 28.8rem);
        max-width: 62.375rem;
        display: inline-block;
        @media screen and (max-width: 900px) {
          max-width: calc(100% - 24rem);
        }
        @media screen and (max-width: 768px) {
          display: none;
          padding-top: 1.6rem;
          max-width: 100%;
        }
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 0;
      }
    }
  }
}
.Polaris-Frame__ContextualSaveBar {
  z-index: 600;
}
