// 解决ant 造成的标题上升问题
h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
  margin-bottom: 0rem;
}
.Polaris-Heading {
  color: #202223;
}

.common-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-spinner {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 10000;
  }
}

.system-make .Polaris-Tag {
  // background-color: #377D62;
  background-color: #aee9d1;
  color: black;
}

p.Polaris-TopBar-UserMenu__Name,
p.Polaris-TopBar-UserMenu__Detail {
  margin-bottom: 0rem;
}
// 禁用的select 背景颜色
.Polaris-Select--disabled .Polaris-Select__Content {
  background-color: rgba(250, 251, 251, 1);
  border: 1px solid rgba(210, 213, 216, 1);
  border-radius: 4px;
}

.special-checkbox_first .Polaris-IndexTable__TableCell--first {
  width: 200px;
}
.special-checkbox_first-omit.special-checkbox_first .Polaris-IndexTable__TableCell:first-child {
  width: 200px;
}

// 约束ant里面的字体
.ant-picker-large .ant-picker-input > input {
  font-size: 14px;
}
.Polaris-DataTable__Navigation {
  display: none !important;
}

