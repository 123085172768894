//  Gx 开头 全局属性

// 长文本自动换行
.GxTextClamp {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

