$color-button: #fff;
$color-button-hover: rgb(138, 57, 249);

.video_poster_twitch {
  position: relative;
  // position: absolute;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin: -40px 0 0 -40px;
    border: 5px solid $color-button;
    border-radius: 100%;
    -webkit-transition: border-color 300ms;
    -moz-transition: border-color 300ms;
    transition: border-color 300ms;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    margin: -24px 0 0 -12px;
    border-left: 40px solid $color-button;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    -webkit-transition: border-color 300ms;
    -moz-transition: border-color 300ms;
    transition: border-color 300ms;
  }
  &:hover,
  &:focus {
    &:before {
      border-color: $color-button-hover;
      box-shadow: 1px 1px 50px white;
      background-color: #fff;
    }
    &:after {
      border-left-color: $color-button-hover;
    }
  }
}

.video_title_wrap {
  padding: 10px 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
