.header .Polaris-TopBar .Polaris-TopBar-Menu__Activator:hover {
  background-color: transparent;
}
.header .Polaris-TopBar .Polaris-TopBar-Menu__Activator:active,
.header .Polaris-TopBar .Polaris-TopBar-Menu__Activator[aria-expanded='true'] {
  background-color: transparent;
}
.header .Polaris-TopBar__LogoLink {
  cursor: default;
}
.header .Polaris-PositionedOverlay--fixed {
  right: 0 !important;
}
.header-logout-warning > .Polaris-InlineError{
  color: orange;
}