@font-face {
  font-family: "iconfont"; /* Project id 3386872 */
  src: url('./iconfont.woff2?t=1659423960769') format('woff2'),
       url('./iconfont.woff?t=1659423960769') format('woff'),
       url('./iconfont.ttf?t=1659423960769') format('truetype'),
       url('./iconfont.svg?t=1659423960769#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fl-PMP-profiles:before {
  content: "\e767";
}

.fl-PMP-platforms:before {
  content: "\e768";
}

.fl-PMP-position:before {
  content: "\e766";
}

.fl-PMP-wechat:before {
  content: "\e763";
}

.fl-PMP-kaokaotalk:before {
  content: "\e764";
}

.fl-PMP-fax:before {
  content: "\e765";
}

.fl-PMP-telephone:before {
  content: "\e762";
}

.fl-PMP-officialwebsite:before {
  content: "\e760";
}

.fl-PMP-pdf:before {
  content: "\e761";
}

.fl-PMP-conversations:before {
  content: "\e735";
}

.fl-PMP-column:before {
  content: "\e736";
}

.fl-PMP-successful:before {
  content: "\e737";
}

.fl-PMP-settings:before {
  content: "\e738";
}

.fl-PMP-hashtag:before {
  content: "\e739";
}

.fl-PMP-plus:before {
  content: "\e73a";
}

.fl-PMP-failed:before {
  content: "\e73b";
}

.fl-PMP-partners:before {
  content: "\e73c";
}

.fl-PMP-favorite:before {
  content: "\e73d";
}

.fl-PMP-account-settings:before {
  content: "\e73e";
}

.fl-PMP-home:before {
  content: "\e73f";
}

.fl-PMP-view:before {
  content: "\e740";
}

.fl-PMP-campaigns:before {
  content: "\e741";
}

.fl-PMP-sort:before {
  content: "\e742";
}

.fl-PMP-mobile-phone:before {
  content: "\e743";
}

.fl-PMP-export:before {
  content: "\e744";
}

.fl-PMP-import:before {
  content: "\e745";
}

.fl-PMP-filters:before {
  content: "\e746";
}

.fl-PMP-cancel:before {
  content: "\e747";
}

.fl-PMP-circle-plus:before {
  content: "\e748";
}

.fl-PMP-ant-upload:before {
  content: "\e749";
}

.fl-PMP-chevron-up:before {
  content: "\e74a";
}

.fl-PMP-caret-down:before {
  content: "\e74b";
}

.fl-PMP-chevron-right:before {
  content: "\e74c";
}

.fl-PMP-search:before {
  content: "\e74d";
}

.fl-PMP-duplicate:before {
  content: "\e74e";
}

.fl-PMP-followers:before {
  content: "\e74f";
}

.fl-PMP-drag-handle:before {
  content: "\e750";
}

.fl-PMP-delete:before {
  content: "\e751";
}

.fl-PMP-mention:before {
  content: "\e752";
}

.fl-PMP-caret-up:before {
  content: "\e753";
}

.fl-PMP-link:before {
  content: "\e754";
}

.fl-PMP-chevron-down:before {
  content: "\e755";
}

.fl-PMP-chevron-left:before {
  content: "\e756";
}

.fl-PMP-select:before {
  content: "\e757";
}

.fl-PMP-navigation:before {
  content: "\e758";
}

.fl-PMP-logout:before {
  content: "\e759";
}

.fl-PMP-emai:before {
  content: "\e75a";
}

.fl-PMP-edit:before {
  content: "\e75b";
}

.fl-PMP-teams:before {
  content: "\e75c";
}

.fl-PMP-company:before {
  content: "\e75d";
}

.fl-PMP-external:before {
  content: "\e75e";
}

.fl-PMP-local:before {
  content: "\e75f";
}

